.header_style {
  background-color: #081a1c;
  padding: 1.26rem 1.26rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.9);
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
}

.header_btn_bRadius {
  border-radius: 0.3rem;
}

.header_text_style {
  padding: 0.6rem;
  text-shadow: 0px -1px 0px rgb(0 0 0 / 80%);
}

@media (max-width: 1278px) {
  .header_flex_smallscreen {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
}
