.section {
	height: 15vh;
	background-color: #0d393a;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.9);
	background-image: linear-gradient(rgba(0, 0, 0, 0.27), transparent);
}

.section_menu_btn_bRadius_hjem {
	border-radius: 0.27rem 0rem 0rem 0.27rem;
}

.section_menu_btn_bRadius_kontact {
	border-radius: 0rem 0.27rem 0.27rem 0rem;
}

@media (max-width: 360px) {
	.section a {
		font-size: 14px;
	}
}

/* button,
input[type='submit'],
input[type='button'],
a.btn,
button.btn,
input[type='submit'].btn,
input[type='button'].btn,
.btn {
  font-family: Cascadia Mono;
  background: #1d016b;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.8);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.2),
    inset 0px -1px 0px rgba(255, 255, 255, 0.1),
    inset 1px 0px 0px rgba(255, 255, 255, 0.05),
    inset -1px 0px 0px rgba(255, 255, 255, 0.05), 0 4px 8px rgba(0, 0, 0, 0.3);

  box-sizing: border-box;
  color: #ebc51e;
  cursor: default;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0.5em 1.25em;
  text-align: center;
  text-decoration: none;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.8);
  vertical-align: middle;
  transition: all 0.15s ease-in-out;
} */
