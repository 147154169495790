.font-roboto {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.flex {
  display: flex;
  justify-content: center;
  padding: 0.9rem;
  margin: 2.7rem;
}

.footer-card-color {
  background-color: #183a40;
  padding: 12px;
}

@media (max-width: 1280px) {
  .flex {
    margin: 0.9rem 0rem;
  }

  .flex p {
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  .contact_tile_flex {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 396px) {
  .contact_tile_flex {
    flex-direction: column;
  }
}
