.profile-border {
  margin-top: 3rem;
  border: 1px solid transparent;
  display: grid;
  grid-gap: 1rem;
  border-radius: 0.3rem;
  grid-template-columns: repeat(3, 1fr);
  padding: 0.9rem;
  transition: all 0.1s linear;
}

.profile-border:hover,
.raised_card {
  background: #234853;
  border: 1px solid rgba(0, 0, 0, 0.226);
  border-radius: 0.3rem;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.2),
    inset 0px -1px 0px rgba(255, 255, 255, 0.1),
    inset 1px 0px 0px rgba(255, 255, 255, 0.05),
    inset -1px 0px 0px rgba(255, 255, 255, 0.05), 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.27s ease-in-out;
}

.raised_card:hover {
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.2),
    inset 0px -1px 0px rgba(255, 255, 255, 0.1),
    inset 1px 0px 0px rgba(255, 255, 255, 0.05),
    inset -1px 0px 0px rgba(255, 255, 255, 0.05), 0 14px 18px #0f3f4b;
  transition: all 0.27s ease-in-out;
}

.preview-image {
  background-image: url(../../../assets/images/profile_img_1.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0.3rem;
  min-height: 27vh;
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.9);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.2),
    inset 0px -1px 0px rgba(255, 255, 255, 0.1),
    inset 1px 0px 0px rgba(255, 255, 255, 0.05),
    inset -1px 0px 0px rgba(255, 255, 255, 0.05), 0px 3px 6px #0f3f4b;
  transition: all 0.27s ease-in-out;
}

.preview-image:hover {
  background-image: url(../../../assets/images/profile_img_2.webp);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.2),
    inset 0px -1px 0px rgba(255, 255, 255, 0.1),
    inset 1px 0px 0px rgba(255, 255, 255, 0.05),
    inset -1px 0px 0px rgba(255, 255, 255, 0.05), 0px 3px 6px #0f3f4b;
  transition: all 0.27s ease-in-out;
}

.intro-text {
  grid-column: span 2;
}

.grid_intro-text {
  display: grid;
  align-content: center;
  justify-items: center;
}

h1,
h2,
h3,
.card p {
  font-weight: 400;
}

.border-red {
  padding: 1rem;
  border: 1px solid transparent;
}

.border-yel {
  margin-top: 10px;
  border: 1px solid yellow;
}

/* section mid - about me */
.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.2rem;
}

.about_me {
  margin: 1rem auto;
  padding: 1rem;
}

.who-am-i {
  margin: 9px 12px;
  padding: 9px 12px;
  text-align: center;
}

.about_me-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.2rem;
}

.about_me-card {
  background: #032b2c;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.8);
  border-radius: 0.27rem;
  background-image: linear-gradient(rgba(0, 0, 0, 0.27), transparent);
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
  display: inline;
  padding: 0.45rem;
  margin: 1rem;
}

.card {
  padding: 0.9rem;
  background: #032b2c;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.8);
  background-image: linear-gradient(rgba(0, 0, 0, 0.27), transparent);
  border: 1px solid rgba(0, 0, 0, 0.8);
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
  border-radius: 0.2rem;
  margin: 0 auto;
  width: 100%;
}

@media (max-width: 1024px) {
  .about_me-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .preview-image {
    min-height: 90vh;
  }

  .grid_intro-text h3 {
    font-size: 14px;
    font-weight: 300;
  }

  .profile-border {
    grid-template-columns: 1fr;
  }

  .horizontal_line {
    margin: 3rem 1rem 3rem 1rem;
    border: 1px solid rgba(197, 67, 7, 0.8);
  }
}

@media (max-width: 900px) {
  .preview-image {
    min-height: 63vh;
  }
}

@media (max-width: 810px) {
  .preview-image {
    min-height: 54vh;
  }
}

@media (max-width: 540px) {
  .preview-image {
    min-height: 36vh;
  }
}
