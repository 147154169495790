* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   color: #dbdbdb;
   outline: none;
}

body {
   /* min-height: 100vh; */
   /* background: #262b30; */
   background: #23272a;
}

.container {
   max-width: 54%;
   margin: auto;
}

.center_text {
   text-align: center;
}

.center_text_flex {
   justify-content: center;
}

.d_flex {
   display: flex;
}

.btn {
   background: #09252e;
   background-image: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0)
   );
   /* border-radius: 0.25rem; */
   border: 1px solid rgba(0, 0, 0, 0.8);
   box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.2),
      inset 0px -1px 0px rgba(255, 255, 255, 0.1),
      inset 1px 0px 0px rgba(255, 255, 255, 0.05),
      inset -1px 0px 0px rgba(255, 255, 255, 0.05), 0 4px 8px rgba(0, 0, 0, 0.3);

   color: #ff7b00;
   cursor: default;
   display: inline-block;
   font-size: 1rem;
   font-weight: 600;
   line-height: 1.2;
   margin: 0;
   margin-bottom: 1rem;
   padding: 0.5em 1.25em;
   text-align: center;
   text-decoration: none;
   text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.8);
   vertical-align: middle;
   transition: all 0.027s ease-in-out;
}

.btn:active {
   background-image: linear-gradient(rgba(0, 0, 0, 0.27), transparent);
   box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.3);
   border: 1px solid rgba(0, 0, 0, 0.9);
   color: #038103;
   transition: all 0.027s ease-in-out;
}

li {
   margin-top: 1.8rem;
}

a,
ul {
   list-style-type: none;
   text-decoration: none;
}

.horizontal_line {
   margin: 9rem 3rem 9rem 3rem;
   border: 1px solid rgba(255, 81, 0, 0.959);
}

.hr_color {
   margin: 0.9rem 0rem;
   border: 1px solid rgba(255, 81, 0, 0.959);
}

.center {
   text-align: center;
}

.bottom-border {
   border-top: transparent;
   border-left: transparent;
   border-right: transparent;
   border-bottom: 1px solid rgba(255, 81, 0, 0.959);
}

@media (max-width: 1332px) {
   .container {
      max-width: 63vw;
   }
}

@media (max-width: 1024px) {
   .container {
      max-width: 81vw;
   }
   h1 {
      font-size: 18px;
   }
   h2 {
      font-size: 15px;
   }
   p {
      font-size: 14px;
   }
}

@media (max-width: 640px) {
   .container {
      max-width: 90vw;
   }
}
